<template>
  <div>
    <table-component
      :model="model"
      :show-form="false"
      url-create-form="/coupons/create"
      url-edit-form="/coupons/edit"
    />
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Coupon from '@/dc-it/models/Coupon'

export default {
  name: 'PromotionView',
  components: { TableComponent },
  data() {
    return {
      model: Coupon,
    }
  },
}
</script>

<style scoped>

</style>
